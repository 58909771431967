<!-- =========================================================================================
  Author: Hossam Ali
  Author URL: https://www.linkedin.com/in/hossam-ali-7bb41810b/
========================================================================================== -->
<template>
  <div>
    <vx-card
      ref="filterCard"
      title="Filters"
      class="user-list-filters mb-8"
      collapse-action
      refresh-content-action
      @refresh="resetColFilters"
    >
      <validation-observer ref="observer">
        <form ref="form" slot-scope="{ validate }" autocomplete="off">
          <div class="vx-row">
            <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75 mr-3">
                <span class="text-primary">*</span>From
              </label>
              <flat-pickr
                :config="configFromdateTimePicker"
                v-model="items.from"
                placeholder="From Date"
                @on-change="onFromChange"
              />
            </div>

            <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75 mr-3">
                <span class="text-primary">*</span>To
              </label>
              <flat-pickr
                :config="configTodateTimePicker"
                v-model="items.to"
                placeholder="To Date"
                @on-change="onToChange"
              />
            </div>

            <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75">
                <span class="text-primary">*</span>Company
              </label>
              <validation-provider
                name="company_id"
                rules="required|min:1"
                v-slot="{ errors }"
              >
                <v-select
                  label="name"
                  :reduce="(name) => name.id"
                  :options="companies"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="items.company_id"
                  class="mb-4"
                  placeholder="Company"
                  :class="{ 'is-valid': !!errors[0] }"
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                />
              </validation-provider>
            </div>
            <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75"> Category </label>
              <validation-provider
                name="category_id"
                rules="required|min:1"
                v-slot="{ errors }"
              >
                <v-select
                  label="name"
                  :reduce="(name) => name.id"
                  :options="categories"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="items.category_id"
                  class="mb-4 md:mb-0"
                  placeholder="Category"
                  :class="{ 'is-valid': !!errors[0] }"
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                />
              </validation-provider>
            </div>
            <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75"> Departments</label>

              <v-select
                :reduce="(name) => name.id"
                label="name"
                :options="departments"
                name="department_id"
                :clearable="false"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                v-model="items.department_id"
                class="mb-4"
                placeholder="Department"
              />
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2" v-if="show">
              <label class="text-sm opacity-75">Job Title</label>
              <v-select
                :reduce="(name) => name.id"
                label="name"
                name="jobtitle_id"
                :options="jobTitles"
                :clearable="false"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                v-model="items.jobtitle_id"
                class="mb-4"
                placeholder="Job Title"
              />
            </div>
            <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2" v-if="show">
              <label class="text-sm opacity-75"> Status </label>
              <v-select
                label="name"
                :reduce="(name) => name.key"
                :options="allStatus"
                :clearable="false"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                v-model="items.status"
                class="mb-4"
                placeholder="Status"
              />
            </div>

            <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2" v-if="show">
              <label class="text-sm opacity-75">Employee Name</label>
              <v-select
                label="FullName"
                :reduce="(FullName) => FullName.id"
                :options="employee"
                class="w-full"
                placeholder="Emloyee Name"
                v-model="items.user_id"
              />
            </div>
            <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2" v-if="show">
              <label class="text-sm opacity-75">Employee Code</label>
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-hash"
                icon-no-border
                v-model="items.code"
              />
            </div>
            <!-- <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-2" v-if="show">
            <label class="text-sm opacity-75">Reporting To</label>
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-user"
              icon-no-border
              v-model="items.reporting_to"
            />
          </div> -->
            <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2" v-if="show">
              <label class="text-sm opacity-75">City</label>
              <v-select
                label="name"
                :options="cities"
                :clearable="false"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                v-model="items.city_id"
                class="mb-4"
                placeholder="City"
              />
            </div>
            <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2" v-if="show">
              <label class="text-sm opacity-75">Area</label>
              <v-select
                label="name"
                :reduce="(name) => name.id"
                :options="areas"
                :clearable="false"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                v-model="items.area_id"
                class="mb-4"
                placeholder="Area"
              />
            </div>

            <!-- <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-2" v-if="show">
            <label class="text-sm opacity-75">Absenteeism Days</label>
            <v-select
              label="name"
              :reduce="(name) => name"
              :options="absenteeismDays"
              :clearable="false"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="items.Absenteeism_Days"
              class="mb-4"
              placeholder="Absenteeism Days"
            />
          </div> -->

            <div class="vx-col md:w-1/2 sm:w-1/2 w-full mt-6">
              <div class="vx-row">
                <div class="vx-col md:w-1/2 sm:w-1/1 w-full">
                  <vs-button
                    type="filled"
                    @click="validate().then(addNewFilter)"
                    class="w-full mb-2"
                    >Filters</vs-button
                  >
                </div>
                <div class="vx-col md:w-1/2 sm:w-1/1 w-full">
                  <vs-button
                    color="dark"
                    type="filled"
                    @click="show = !show"
                    class="w-full mb-2"
                  >
                    Advanced Filters</vs-button
                  >
                </div>
              </div>
            </div>
          </div>
        </form>
      </validation-observer>
    </vx-card>
    <vx-card class="vs-con-loading__container" id="div-with-loading">
      <vs-prompt
        title="Export To Excel"
        class="export-options"
        @cancle="clearFields"
        @accept="exortNewFilter"
        accept-text="Export"
        @close="clearFields"
        :active.sync="activePrompt"
      >
        <vs-input
          v-model="fileName"
          placeholder="Enter File Name.."
          class="w-full"
        />
        <v-select v-model="selectedFormat" :options="formats" class="my-4" />
        <div class="flex">
          <span class="mr-4">Cell Auto Width:</span>
          <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
        </div>
      </vs-prompt>

      <div class="export-table">
        <vs-table
          class="style-table"
          ref="table"
          :data="itemsData"
          search
          pagination
          :max-items="itemsPerPage"
        >
          <template slot="header">
            <!-- ITEMS PER PAGE -->
            <vs-dropdown
              vs-trigger-click
              class="cursor-pointer mb-4 mr-4 items-per-page-handler"
            >
              <div
                class="
                  p-4
                  border border-solid
                  d-theme-border-grey-light
                  rounded-full
                  d-theme-dark-bg
                  cursor-pointer
                  flex
                  items-center
                  justify-between
                  font-medium
                "
              >
                <span class="mr-2"
                  >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
                  {{
                    itemsData.length - currentPage * itemsPerPage > 0
                      ? currentPage * itemsPerPage
                      : itemsData.length
                  }}
                  From {{ queriedItems }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="itemsPerPage = 10">
                  <span>10</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 15">
                  <span>15</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 20">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 25">
                  <span>25</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>

            <vs-button @click="activePrompt = true" class="mb-4">
              <i class="vs-icon material-icons mr-1">save_alt</i>
              Export</vs-button
            >
          </template>

          <template slot="thead">
            <vs-th> Employee Code </vs-th>
            <vs-th> Employee Full Name </vs-th>
            <vs-th> First Name </vs-th>
            <vs-th> Last Name </vs-th>
            <vs-th> National ID </vs-th>
            <vs-th> Company </vs-th>
            <vs-th> Category </vs-th>
            <vs-th> Department </vs-th>
            <vs-th> Job Title </vs-th>
            <vs-th> Private mob. </vs-th>
            <vs-th> Business mob. </vs-th>
            <vs-th> E-mail </vs-th>
            <vs-th> Serial mob. </vs-th>
            <vs-th> Start Date </vs-th>
            <vs-th> Roles </vs-th>
            <vs-th> Login_Areas </vs-th>
            <vs-th> Notes </vs-th>
            <vs-th> Picture </vs-th>
            <vs-th> Is Top Manager </vs-th>
            <vs-th> Managers </vs-th>
            <vs-th> Status </vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>{{ data[indextr].Code }}</vs-td>
              <vs-td>{{ data[indextr].FullName }}</vs-td>
              <vs-td>{{ data[indextr].FirstName }}</vs-td>
              <vs-td>{{ data[indextr].LastName }}</vs-td>
              <vs-td>{{ data[indextr].nationalId }}</vs-td>
              <vs-td>{{ data[indextr].Company }}</vs-td>
              <vs-td>{{ data[indextr].Category }}</vs-td>
              <vs-td>{{ data[indextr].department }}</vs-td>
              <vs-td>{{ data[indextr].JobTitle }}</vs-td>
              <vs-td>{{ data[indextr].private_mobile }}</vs-td>
              <vs-td>{{ data[indextr].buisness_mobile }}</vs-td>
              <vs-td>{{ data[indextr].email}}</vs-td>
              <vs-td>{{ data[indextr].serial_mobile }}</vs-td>
              <vs-td>{{ data[indextr].StartDate }}</vs-td>
              <vs-td>{{ data[indextr].roles.map(item => item).join(", ") }}</vs-td>
              <vs-td>{{ data[indextr].areas.map(item => item.name).join(", ") }}</vs-td>
              <vs-td>{{ data[indextr].notes }}</vs-td>
              <vs-td>{{ data[indextr].image }}</vs-td>
              <vs-td>{{ data[indextr].isTopManager }}</vs-td>
              <vs-td>{{ data[indextr].managers.map(item => item).join(", ") }}</vs-td>
              <vs-td>{{ data[indextr].status }}</vs-td>
              <template class="expand-user" slot="expand">
                <table class="expand-table">
                  <thead>
                    <tr>
                      <th>Login_time_in</th>
                      <th>Login_time_out</th>
                      <th>Login_day</th>
                      <th>Login_area</th>
                      <th>Login_total_working_time</th>
                      <th>Login_total_latency_time</th>
                      <th>Login_total_over_time</th>
                      <th>Login_city</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      :key="indexnestedtr"
                      v-for="(nestedtr, indexnestedtr) in tr.attendances"
                    >
                      <td>{{ nestedtr.time_in }}</td>
                      <td>{{ nestedtr.time_out }}</td>
                      <td>{{ nestedtr.day }}</td>
                      <td>{{ nestedtr.area }}</td>
                      <td>{{ nestedtr.total_working_time }}</td>
                      <td>{{ nestedtr.total_latency_time }}</td>
                      <td>{{ nestedtr.total_over_time }}</td>
                      <td>{{ nestedtr.city }}</td>
                    </tr>
                  </tbody>
                </table>
              </template>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </vx-card>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import endpoints from "../../../endpoints";
import axios from "@/axios.js";
import vSelect from "vue-select";

// Store Module
import moduleHrReportManagement from "@/store/hr-report-management/moduleHrReportManagement.js";
import moduleCompaniesManagement from "@/store/get-all-companies/moduleCompaniesManagement.js";
import moduleDepartmentManagement from "@/store/get-all-departments/moduleDepartmentManagement.js";
import moduleEmployeeManagement from "@/store/get-all-employees/moduleEmployeeManagement.js";
import moduleCategoriesManagement from "@/store/get-all-categories/moduleCategoriesManagement.js";
import moduleJobTitleManagement from "@/store/get-all-jobs/moduleJobTitleManagement.js";

import { required, min } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
setInteractionMode("eager");
// betterEager
extend("required", {
  ...required,
  message: "field can not be empty",
});
extend("min", {
  ...min,
  message: "field may not be less than {length} characters",
});
export default {
  components: {
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      itemsPerPage: 10,
      isMounted: false,

      show: false,
      allStatus: [
        { key: "pending", name: "Pending" },
        { key: "accepted", name: "Accepted" },
        { key: "refused", name: "Refused" },
      ],
      cities: [],
      areas: [],
      departments: [],
      jobTitles: [],
      city: null,
      department: null,
      items: {
        from: null,
        to: null,
        category_id: null,
        company_id: null,
        department_id: null,
        jobtitle_id: null,
        user_id: null,
        code: null,
        city_id: null,
        area_id: null,
        status: null,
      },
      configFromdateTimePicker: {
        allowInput: true,
        minDate: null,
        maxDate: new Date(),
      },
      configTodateTimePicker: {
        minDate: null,
        maxDate: new Date(),
      },

      fileName: "",
      formats: ["xlsx", "csv", "txt", "dbf"],
      cellAutoWidth: true,
      selectedFormat: "xlsx",
      headerTitle: [
        "Employee Code",
        "Employee Full Name",
        "First Name",
        "Last Name",
        "National ID",
        "Company",
        "Category",
        "Department",
        "Job Title",
        "Private mob.",
        "Business mob.",
        "E-mail",
        "Serial mob.",
        "Start Date",
        "Roles",
        "Login_area",
        "Notes",
        "Picture",
        "Is Top Manager",
        "Managers",
        "Status",
        "Login_Day",
        "Login_Time_in",
        "Login_Time out",
        "Login_Total latency",
        "Login_total_over_time",
        "Login_total_working_time",
        "Login_City",
      ],
      headerVal: [
        "Code",
        "FullName",
        "FirstName",
        "LastName",
        "nationalId",
        "Company",
        "Category",
        "department",
        "JobTitle",
        "private_mobile",
        "buisness_mobile",
        "email",
        "serial_mobile",
        "StartDate",
        "roles",
        "area",
        "notes",
        "image",
        "isTopManager",
        "Manager",
        "status",
        "day",
        "time_in",
        "time_out",
        "total_latency",
        "total_over_time",
        "total_working_time",
        "city"
      ],
      activePrompt: false,
    };
  },
  watch: {
    "items.company_id"(obj) {
      this.items.department_id = null;
      this.items.jobtitle_id = null;
      this.departments = [];
      this.jobTitles = [];
      if (obj) {
        this.$vs.loading({
          container: "#div-with-loading",
          scale: 0.6,
        });
        axios
          .get(`${endpoints.API_URL}company/${obj}/departments`)
          .then((response) => {
            this.$vs.loading.close("#div-with-loading > .con-vs-loading");
            this.departments = response.data.response.data;
          });
      }
    },
    "items.department_id"(obj) {
      this.items.jobtitle_id = null;
      this.jobTitles = [];
      if (obj) {
        this.$vs.loading({
          container: "#div-with-loading",
          scale: 0.6,
        });
        axios
          .get(`${endpoints.API_URL}department/${obj}/job_titles`)
          .then((response) => {
            this.$vs.loading.close("#div-with-loading > .con-vs-loading");
            this.jobTitles = response.data.response.data;
          });
      }
    },
    "items.city_id"(obj) {
      axios
        .get(`${process.env.VUE_APP_BASE_URI}/api/admin/city/${obj.id}/areas`)
        .then((response) => {
          this.areas = response.data.response.data;
        });
    },
    to(obj) {
      if (obj.length === 5) {
        this.to = obj + ":00";
      }
    },
    from(obj) {
      if (obj.length === 5) {
        this.from = obj + ":00";
      }
    },
  },
  computed: {
    itemsData() {
      return this.$store.state.hrReportManagement.items;
    },
    exportItemsData() {
      return this.$store.state.hrReportManagement.items_export;
    },
    companies() {
      return this.$store.state.company.items;
    },
    categories() {
      return this.$store.state.category.items;
    },
    employee() {
      return this.$store.state.employees.items;
    },
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.itemsData.length;
    },
  },
  methods: {
    exportToExcel() {
      import("@/vendor/Export2Excel").then((excel) => {
        const list = this.exportItemsData;
        list.forEach(item => {
          item.Manager = item.managers.map(m => m.manager).join(', ')
        })
        const data = this.formatJson(this.headerVal, list);

        excel.export_json_to_excel({
          header: this.headerTitle,
          data,
          filename: this.fileName,
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat,
        });
        this.clearFields();
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          // Add col name which needs to be translated

          // if (j === "attendances") {
          //   // console.log(JSON.stringify(v[j]));
          //   return JSON.stringify(v[j]);
          // } else if (j === "areas") {
          //   return v[j].map((i) => i.name);
          // } else {
          //   return v[j];
          // }

          return v[j];
        })
      );
    },
    clearFields() {
      (this.filename = ""),
        (this.cellAutoWidth = true),
        (this.selectedFormat = "xlsx");
    },
    addNewFilter(params) {
      if (!this.items.from || !this.items.to || !this.items.company_id || !this.items.category_id ) {
        this.$vs.notify({
          title: "Error",
          text: "Make sure select the main filters ",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        return;
      }
      if (params) {
        this.$vs.loading({
          container: "#div-with-loading",
          scale: 0.6,
        });
        let params = {
          from: this.items.from,
          to: this.items.to,
          category_id: this.items.category_id,
          company_id: this.items.company_id,
          department_id: this.items.department_id,
          jobtitle_id: this.items.jobtitle_id,
          user_id: this.items.user_id,
          code: this.items.code,
          area_id: this.items.area_id,
          status: this.items.status,
        };
        this.$store

          .dispatch("hrReportManagement/fetchItemsMainReport", params)
          .then((response) => {
            this.$vs.loading.close("#div-with-loading > .con-vs-loading");
            if (response.data.response.data.length === 0) {
              this.$vs.notify({
                title: "Information",
                text: "No data available",
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success",
              });
            }
          })
          .catch((error) => {
            this.$vs.loading.close("#div-with-loading > .con-vs-loading");
            this.$vs.notify({
              title: "Error",
              text: error.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          });
      } else {
        // form have errors
      }
    },
    exortNewFilter() {
      if (!this.items.from || !this.items.to || !this.items.company_id) {
        this.$vs.notify({
          title: "Error",
          text: "Make sure select the main filters ",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        return;
      }

      this.$vs.loading({
        container: "#div-with-loading",
        scale: 0.6,
      });
      let params = {
        from: this.items.from,
        to: this.items.to,
        category_id: this.items.category_id,
        company_id: this.items.company_id,
        department_id: this.items.department_id,
        jobtitle_id: this.items.jobtitle_id,
        user_id: this.items.user_id,
        code: this.items.code,
        area_id: this.items.area_id,
        status: this.items.status,
      };
      this.$store

        .dispatch("hrReportManagement/exportItemsMainReport", params)
        .then((response) => {
          this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          if (response.data.response.data.length === 0) {
            this.$vs.notify({
              title: "Information",
              text: "No data available",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });
          } else {
            this.exportToExcel();
          }
        })
        .catch((error) => {
          this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          this.$vs.notify({
            title: "Error",
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
    onFromChange(selectedDates, dateStr) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
    resetColFilters(card) {
      card.removeRefreshAnimation(500);
      this.items.start_date =
        this.items.end_date =
        this.category =
        this.company =
        this.items.job_title =
        this.items.employee_name =
        this.items.employee_code =
        this.items.reporting_to =
        this.items.city =
        this.city =
        this.items.area =
        this.items.area_name =
        this.items.Absenteeism_Days =
          null;

      // Reset Grid Filter
      // this.gridApi.setFilterModel(null);
      // this.gridApi.onFilterChanged();
      // this.$refs.filterCard.removeRefreshAnimation();
    },
    getCompanies() {
      this.$vs.loading({
        container: "#div-with-loading",
        scale: 0.6,
      });
      this.$store.dispatch("company/fetchItemsCompanies").then((response) => {
        this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        if (response.data.status === 404) {
          this.$vs.notify({
            title: "Error",
            text: "Make sure select the main filters ",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      });
    },
    getDepartments() {
      this.$vs.loading({
        container: "#div-with-loading",
        scale: 0.6,
      });
      this.$store.dispatch("department/getAlls").then((response) => {
        this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        if (response.data.status === 404) {
          this.$vs.notify({
            title: "Error",
            text: "Make sure select the main filters ",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      });
    },
    getCategories() {
      this.$vs.loading({
        container: "#div-with-loading",
        scale: 0.6,
      });
      this.$store.dispatch("category/fetchItemsCategories").then((response) => {
        this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        if (response.data.status === 404) {
          this.$vs.notify({
            title: "Error",
            text: "Make sure select the main filters ",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      });
    },
    getEmployee() {
      this.$vs.loading({
        container: "#div-with-loading",
        scale: 0.6,
      });
      this.$store.dispatch("employees/getAlls").then((response) => {
        this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        if (response.data.status === 404) {
          this.$vs.notify({
            title: "Error",
            text: "Make sure select the main filters ",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      });
    },
    getJobTitles() {
      this.$vs.loading({
        container: "#div-with-loading",
        scale: 0.6,
      });
      this.$store.dispatch("jobTitle/getAlls").then((response) => {
        this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        if (response.data.status === 404) {
          this.$vs.notify({
            title: "Error",
            text: "Make sure select the main filters ",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      });
    },
  },
  mounted() {
    this.getCompanies();
    this.getDepartments();
    this.getCategories();
    this.getJobTitles();
    this.getEmployee();
    this.isMounted = true;

    axios
      .get(`${process.env.VUE_APP_BASE_URI}/api/admin/cities`)
      .then((response) => {
        this.cities = response.data.response.data;
      });
  },
  created() {
    if (!moduleHrReportManagement.isRegistered) {
      this.$store.registerModule(
        "hrReportManagement",
        moduleHrReportManagement
      );
      moduleHrReportManagement.isRegistered = true;
    }
    if (!moduleCompaniesManagement.isRegistered) {
      this.$store.registerModule("company", moduleCompaniesManagement);
      moduleCompaniesManagement.isRegistered = true;
    }
    if (!moduleJobTitleManagement.isRegistered) {
      this.$store.registerModule("jobTitle", moduleJobTitleManagement);
      moduleJobTitleManagement.isRegistered = true;
    }
    if (!moduleDepartmentManagement.isRegistered) {
      this.$store.registerModule("department", moduleDepartmentManagement);
      moduleDepartmentManagement.isRegistered = true;
    }
    if (!moduleCategoriesManagement.isRegistered) {
      this.$store.registerModule("category", moduleCategoriesManagement);
      moduleCategoriesManagement.isRegistered = true;
    }
    if (!moduleEmployeeManagement.isRegistered) {
      this.$store.registerModule("employees", moduleEmployeeManagement);
      moduleEmployeeManagement.isRegistered = true;
    }
  },
};
</script>
<style lang="scss">
.vs-con-table td.td-check {
  border: 1px solid #dcdfe6;
  border-right: 0;
}
.vs-con-table td.td-check .vs-icon {
  font-size: 1.5rem;
  vertical-align: middle;
}
[dir] .vs-con-table .vs-con-tbody .vs-table--tbody-table .vs-table--thead th {
  padding: 10px 15px;
  border: 1px solid #dcdfe6;
  color: #606266;
  vertical-align: bottom;
  border-bottom: 1px solid #dcdfe6;
  padding-right: 1.5em;
  background: linear-gradient(#f4f5f8, #f1f3f6);
}
[dir]
  .vs-con-table
  .vs-con-tbody
  .vs-table--tbody-table
  .tr-values
  .vs-table--td {
  padding: 0.75em;
  vertical-align: top;
  border: 1px solid #dcdfe6;
  color: #606266;
}
.vs-table--tbody-table tr.tr-expand .content-tr-expand {
  padding: 30px;
  background-color: #fbfbfb;
}
.expand-table {
  border-collapse: collapse;
  background-color: #fff;
  width: 100%;
  max-width: 100%;
  table-layout: auto;
  border: 1px solid #dcdfe6;
}
.expand-table thead th {
  color: #606266;
  vertical-align: bottom;
  border: 1px solid #dcdfe6;
  padding: 0.75em 1.5em 0.75em 0.75em;
  padding-right: 1.5em;
  background: linear-gradient(#f4f5f8, #f1f3f6);
}
.expand-table td {
  padding: 0.75em;
  font-size: 0.85rem;
  vertical-align: top;
  border: 1px solid #dcdfe6;
  color: #606266;
}
</style>
